.about-as{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    max-width: 1180px;
    margin: 59px auto 0;
    
    &_title{

    }

    &_sub-title{
        font-family: Montserrat700;
        font-size: 32px;
        line-height: 39px;
        margin-top: 48px;
        text-transform: uppercase;
    }

    &_list>.list>ul{
       width: 785px;
       padding-left: 24px;
       &>li{
           padding-left:50px ;
       }
    }

    &_modail-info{
        position: relative;
        display: flex;
        margin: 60px 0;
        padding: 40px 105px;
        background: linear-gradient(90.3deg, #fffcfc51 6.36%, #ffffff84 101.69%);
        box-shadow: 0px 0px 25px #00000012;
        border-radius: 58.5px;
        &>span{
            max-width: 970px;
            font-family: Montserrat400;
            font-size: 23px;
            line-height: 28px;
        }
    }

    &_creater{
        display: flex;
        justify-content: space-evenly;
    }
}

.create{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    &_photo{
        width: 233px;
        height: 233px;
        margin-bottom: 32px;
        border-radius: 50%;
    }
    &_name{
        font-family: Montserrat700;
        font-size: 23px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 16px;

    }
    &_faq{
        font-family: Montserrat400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }
}